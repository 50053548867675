import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function Leadership() {
  const data = useStaticQuery(graphql`
    {
      wpPage(slug: { eq: "about" }) {
        id
        title
        content
        slug
        uri
        team {
          teamMember {
            bio
            name
            photo {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1

                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
            title
          }
        }
      }
    }
  `)
  const teamMembers = data.wpPage?.team.teamMember
  const content = data.wpPage.content

  return (
    <div className="bg-primary py-16 px-4">
      {" "}
      <section className="team">
        <h2 className="text-center font-bold text-5xl pb-12">Leadership</h2>
        <div className="flex flex-col md:flex-row w-full mb-16 ">
          {teamMembers.map(member => {
            return <TeamMember key={member.name} member={member} />
          })}
        </div>
      </section>
    </div>
  )
}

function TeamMember({ member }) {
  const { name, title, bio } = member
  return (
    <div className="flex flex-col w-full md:w-1/2 h-full space-y-4 mb-4 md:mb-0">
      <div className="p-2">
        <GatsbyImage
          image={getImage(member.photo.localFile.childImageSharp)}
          alt={member.name}
        />
      </div>
      <div className="flex flex-col space-y-4 h-full px-2">
        <div className="font-bold ">
          <p>{name}</p>
          <p>{title && title}</p>
        </div>
        <div>
          <div dangerouslySetInnerHTML={{ __html: bio }} />
        </div>
      </div>
    </div>
  )
}
