import * as React from "react"

import Ingress from "../components/Ingress"
import IconQuotes from "../components/IconQuotes"
import Layout from "../components/layout"
import Leadership from "../components/Leadership"
import Portfolio from "../components/Portfolio"

import Seo from "../components/seo"

const IndexPage = () => (
  <Layout isHome>
    <Seo isHome title="Newbury Pharma" />

    <div id="about">
      {/* <div>
        <Banner />
      </div> */}
      <div>
        <Ingress />
      </div>
      <div>
        <Leadership />
      </div>
      <div>
        <Portfolio />
      </div>

      <div className="px-4">
        <IconQuotes />
      </div>
    </div>
  </Layout>
)

export default IndexPage
