import React from "react"
import heart from "../images/heart.png"
import person from "../images/person.png"
import house from "../images/house.png"

export default function IconQuotes() {
  return (
    <div className="grid md:grid-cols-3 gap-8 text-primary items-center max-w-6xl mx-auto py-16 justify-center">
      <div className="flex flex-col items-center justify-center">
        <div className="w-48 p-4">
          <img src={person} alt="Person Icon" />
        </div>
        <div className="text-center block md:hidden">
          Our culture is built around deep knowlegde, openness and curiosity
        </div>
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="w-48 p-4">
          <img src={house} alt="House Icon" />
        </div>
        <div className="text-center block md:hidden">
          Your local champion and alternative partner{" "}
        </div>
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="w-48">
          <img src={heart} alt="Heart Icon" />
        </div>
        <div className="text-center block md:hidden">
          We strive to have a positive impact in the society{" "}
        </div>
      </div>
      <div className="text-center hidden md:block">
        Our culture is built around deep knowlegde, openness and curiosity
      </div>
      <div className="text-center hidden md:block">
        Your local champion and alternative partner{" "}
      </div>
      <div className="text-center hidden md:block">
        We strive to have a positive impact in the society{" "}
      </div>
    </div>
  )
}
