import React from "react"
import Button from "./Button"
import { useStaticQuery, graphql } from "gatsby"
export default function Portfolio() {
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 346 }) {
        id
        title
        portfolio {
          portfolioContent
          portfolioHeading
        }
      }
    }
  `)
  const { portfolioHeading, portfolioContent } = data.wpPage.portfolio
  return (
    <div className="" id="portfolio">
      <div className="max-w-2xl mx-auto py-16 md:py-24 space-y-16">
        <div className="text-center  ">
          <h2 className="text-2xl md:text-5xl font-bold leading-tight">
            {portfolioHeading}
          </h2>
        </div>
        <div className="space-y-4  px-4 text-center md:text-left">
          <div dangerouslySetInnerHTML={{ __html: portfolioContent }} />
          <div className="py-8 text-center">
            <Button text="Read more" link="/portfolio/" />
          </div>
        </div>
      </div>
    </div>
  )
}
